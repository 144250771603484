/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import Header from "./header"

type LayoutProps = {
  page: string
  children: any
}

const Layout = ({ children, page }: LayoutProps) => {
  let isRoot = page === `/`
  return (
    <Themed.div
      sx={{
        marginLeft: `auto`,
        marginRight: `auto`,
      }}
    >
      {!isRoot && <Header page={page} />}
      <main>{children}</main>
      <footer>
        <Themed.div sx={{ marginX: "3em" }}>
          © Mukul Rathi {new Date().getFullYear()}
        </Themed.div>
      </footer>
    </Themed.div>
  )
}

export default Layout
