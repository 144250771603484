import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
// @ts-ignore  (import is fine)
import { getSrc } from "gatsby-plugin-image"

type SEOProps = {
  pageType: "blog" | "blogPost" | "other"
  description?: string
  lang?: string
  title: string
  path: string
  FAQs?: { question: string; answer: string }[]
  datePublished?: string
  dateModified?: string
  imageNode?: {
    childImageSharp: {
      gatsbyImageData: any
    }
  }
}

type SiteQueryType = {
  site: {
    siteMetadata: {
      defaultTitle: string
      defaultDescription: string
      author: string
      siteUrl: string
      social: {
        twitter: string
      }
    }
  }
  file: {
    childImageSharp: {
      gatsbyImageData: any
    }
  }
  allMdx: {
    edges: {
      node: {
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const SEO = ({
  pageType,
  description,
  lang,
  title,
  imageNode,
  path,
  datePublished,
  dateModified,
  FAQs,
}: SEOProps) => {
  const { site, allMdx, file }: SiteQueryType = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl
            author
            social {
              twitter
            }
          }
        }
        file(relativePath: { eq: "profile-pic.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
        allMdx(
          sort: { fields: [frontmatter___datePublished], order: DESC }
          filter: { frontmatter: { audience: { ne: me } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )
  const { siteUrl, social, defaultTitle, defaultDescription, author } =
    site.siteMetadata
  let profileImageSrc = getSrc(file)
  let profileImageUrl = siteUrl + profileImageSrc
  let imageUrl = imageNode ? siteUrl + getSrc(imageNode) : profileImageUrl
  let pageUrl = siteUrl + path
  const metaDescription = description ?? defaultDescription
  const metaTitle = title ?? defaultTitle
  var structuredData = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      pageUrl,
      name: title,
    },
  ]
  // subsequent ts-ignore statements are because structured data objects can have different fields
  switch (pageType) {
    case "blog":
      const listItems = allMdx.edges.map(({ node }, index) => ({
        "@type": "ListItem",
        position: index + 1,
        url: siteUrl + node.fields.slug,
      }))
      structuredData.push({
        "@context": "https://schema.org",
        "@type": "ItemList",
        // @ts-ignore (itemListElement not in object)
        itemListElement: listItems,
      })
    case "blogPost":
      const breadCrumb = [
        {
          "@type": "ListItem",
          position: 1,
          name: "Blog",
          item: siteUrl + "/blog",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: title,
          item: pageUrl,
        },
      ]
      structuredData = [
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          // @ts-ignore
          itemListElement: breadCrumb,
        },
        {
          "@context": "https://schema.org",
          "@type": "Article",
          // @ts-ignore
          headline: metaTitle,
          datePublished,
          dateModified: dateModified ?? datePublished,
          author: {
            "@type": "Person",
            name: "Mukul Rathi",
          },
          publisher: {
            "@type": "Organization",
            name: "Mukul Rathi",
            logo: {
              "@type": "ImageObject",
              url: profileImageUrl,
              width: 1207,
              height: 1192,
            },
          },
          image: {
            "@type": "ImageObject",
            url: imageUrl,
          },
          inLanguage: "English",
          description: metaDescription,
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": pageUrl,
          },
        },
      ]
      if (FAQs) {
        const faqJsonLD = FAQs.map(faq => ({
          "@type": "Question",
          name: faq.question,
          acceptedAnswer: {
            "@type": "Answer",
            text: faq.answer,
          },
        }))
        structuredData.push({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          // @ts-ignore
          mainEntity: faqJsonLD,
        })
      }
  }

  return (
    // @ts-ignore (complaining about Helmet)
    <Helmet
      htmlAttributes={{
        lang: lang ?? "en",
      }}
      title={metaTitle}
      meta={[
        {
          name: `image`,
          content: imageUrl,
        },

        {
          name: `author`,
          content: author,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:url`,
          content: pageUrl,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: pageType == "blogPost" ? `article` : `website`,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: social.twitter,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: imageUrl,
        },
      ]}
    >
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  )
}

export default SEO
