/** @jsx jsx */
import { Link } from "gatsby"
// @ts-ignore  (Flex import is fine)
import { jsx, Themed, Flex } from "theme-ui"
import Switch from "./switch"

const Home = ({ page }: { page: string }) => (
  <Themed.div sx={{ marginLeft: "3em" }}>
    <LinkText page={page} text="MUKUL RATHI" link="/" />
  </Themed.div>
)

const LinkText = ({
  text,
  link,
  page,
}: {
  text: string
  link: string
  page: string
}) => (
  <Link
    to={link}
    style={{
      textDecoration: "none",
    }}
  >
    <Themed.div
      sx={{
        fontSize: 1,
        fontFamily: "heading",
        color: `navBarPrimary`,
        marginRight: "2em",
        borderBottom: "3px solid",
        borderBottomColor: page === link ? `white` : `transparent`,
        paddingBottom: "2px",
        transition: " 0.3s ease-in",
        ":hover": {
          color: `navBarPrimary`,
          transform: "scale(1.1)",
        },
      }}
    >
      {text}
    </Themed.div>
  </Link>
)

const RightHandNav = ({ page }: { page: string }) => (
  <Flex
    sx={{
      flexWrap: "nowrap" /* nav bar only 1 row */,
      justifyContent: "flex-end",
      marginRight: "3em",
    }}
  >
    <LinkText page={page} text="About Me" link="/about-me" />
    <LinkText page={page} text="Blog" link="/blog" />
    <Themed.div sx={{ marginLeft: "0.5em" }}>
      <Switch />
    </Themed.div>
  </Flex>
)

const Header = ({ page }: { page: string }) => {
  let isRoot = page === `/`
  return (
    <header>
      <Flex
        sx={{
          backgroundColor: isRoot ? `transparent` : `navBarBackground`,
          zIndex: 1,
          paddingY: "2em",
          justifyContent: "space-between",
          width: "100vw",
          top: 0,
        }}
      >
        {isRoot ? <div /> : <Home page={page} />}
        <Flex
          sx={{
            justifyContent: "flex-end",
          }}
        >
          <RightHandNav page={page} />
        </Flex>
      </Flex>
    </header>
  )
}

export default Header
