/** @jsx jsx */
import ReactSwitch from "react-switch"
// @ts-ignore  (Image import is fine)
import { jsx, useColorMode, Image, Themed } from "theme-ui"
import { DarkModeSwitch } from "react-toggle-dark-mode"

const Switch = () => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = () => setColorMode(isDark ? `light` : `dark`)

  return (
    <Themed.div
      sx={{
        maxWidth: `container`,
      }}
    >
      <Themed.div
        sx={{
          display: `flex`,
          justifyContent: `space-between`,
          alignItems: `center`,
        }}
      >
        <DarkModeSwitch
          style={{ marginBottom: "2rem" }}
          checked={isDark}
          onChange={toggleColorMode}
          moonColor="white"
          sunColor="white"
        />
      </Themed.div>
    </Themed.div>
  )
}

export default Switch
